<script setup lang="ts">
import { onMounted, ref } from "vue";
import { setPageTitle } from "@/utils/pageTitle";
// @ts-ignore
import useUsers from "@/hooks/useUsers";
// @ts-ignore
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useDisplay } from "vuetify";
import { useToast } from "vue-toastification";
import useSupportTickets from "@/hooks/useSupportTickets";
import useTimezones from "@/hooks/useTimezone";
import UserPermissionsCard from "@/components/user/UserPermissionsCard.vue";
import useWarehouses from "@/hooks/useWarehouses";

const { createUser } = useUsers();
const { warehouses, fetchWarehouses } = useWarehouses();

const { supportTicketRoles } = useSupportTickets();

const { timezones, fetchTimezones } = useTimezones();

const { mdAndUp } = useDisplay();
const userStore = useUserStore();
const router = useRouter();
const toast = useToast();
const userData = ref({
  name: null,
  email: null,
  title: null,
  api_token: null,
  phone: "",
  pin: null,
  pin_confirmation: null,
  password: null,
  password_confirmation: null,
  local_timezone: null,
  default_warehouse_id: null,
  enforce_v3_login_timeout: true,
  v3_login_timeout_ms: 20_000,
  user_permissions: {
    support_ticket_role: "user",
    create_lp_label: false,
    create_pallet_label: false,
    adjust_lp: false,
    update_lp: false,
    sync_lp_quantity: false,
    adjust_inbound_order: false,
    cancel_inbound_order: false,
    adjust_outbound_order: false,
    cancel_outbound_order: false,
    access_inbound_uploader: false,
    access_outbound_uploader: false,
    access_product_uploader: false,
    access_slo_uploader: false,
    access_transport_uploader: false,
    create_user_account: false,
    update_user_account: false,
    delete_user_account: false,
    view_users: false,
    view_clients: false,
    update_user_permissions: false,
    create_warehouse: false,
    update_warehouse: false,
    delete_warehouse: false,
    edit_billing: false,
  },
});

onMounted(async () => {
  setPageTitle("Create User");
  if (!userStore.hasPermissionTo("create_user_account")) {
    toast.error("You do not have permission to create a user account");
    router.push(`/users`);
  }
  await fetchTimezones();
  await fetchWarehouses();
});

const handleCreateUser = async () => {
  await createUser(userData.value).then((res: any) => {
    if (res.status == 201) {
      router.push(`/users`);
    }
  });
};
</script>

<template>
  <div class="d-flex align-center justify-space-between px-4">
    <div class="d-flex align-center">
      <div>
        <v-card-title class="d-flex align-center pb-0"> Create a new user </v-card-title>
        <v-card-subtitle>Use this section to create a new user. </v-card-subtitle>
      </div>
    </div>
  </div>
  <v-card flat class="mx-4 mt-8">
    <v-card-title>User Info</v-card-title>
    <v-card-subtitle
      >General user information. Please make sure this is filled out
      accurately!</v-card-subtitle
    >
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="userData.name" label="Name" name="name"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="userData.email"
            label="E-mail Address"
            name="email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-phone-input v-model="userData.phone" name="phone" />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="userData.title"
            label="Title"
            name="title"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="d-flex">
          <v-switch
            v-model="userData.enforce_v3_login_timeout"
            color="primary"
            label="Enforce Login Timeout"
          ></v-switch>
          <v-text-field
            v-if="userData.enforce_v3_login_timeout"
            v-model="userData.v3_login_timeout_ms"
            class="ml-4"
            label="Timeout (ms)"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card flat class="mx-4 mt-8">
    <v-card-title>Password</v-card-title>
    <v-card-subtitle
      >The password is used to any web based applications. Leaving these fields blank will
      randomly generate a password for the new user.</v-card-subtitle
    >
    <v-card-text>
      <v-row no-gutters :class="mdAndUp ? 'w-50' : 'w-100'">
        <v-col cols="12">
          <v-text-field
            v-model="userData.password"
            label="Password"
            type="password"
            name="password"
            :rules="[
              (v) => (v && v.length > 6) || 'Password must be more than 6 characters',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="userData.password_confirmation"
            :rules="[
              (v) => (v && v.length > 6) || 'Password must be more than 6 characters',
            ]"
            label="Confirm Password"
            type="password"
            name="password_confirmation"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card flat class="mx-4 mt-8">
    <v-card-title>PIN</v-card-title>
    <v-card-subtitle
      >The PIN is used to access the scanner app and must be 6 digits.</v-card-subtitle
    >
    <v-card-text>
      <v-row no-gutters :class="mdAndUp ? 'w-50' : 'w-100'">
        <v-col cols="12">
          <v-text-field
            v-model="userData.pin"
            label="Pin"
            type="password"
            name="pin"
            :rules="[(v) => (v && v.length == 6) || 'Pin must be 6 digits']"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="userData.pin_confirmation"
            :rules="[(v) => (v && v.length == 6) || 'Pin must be 6 digits']"
            label="Confirm Pin"
            type="password"
            name="pin_confirmation"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card flat class="mx-4 mt-8">
    <v-card-title>Local Timezone</v-card-title>
    <v-card-subtitle
      >Set your local timezone here to view dates according to your location. If not
      configured, the system will default to your browser's timezone.</v-card-subtitle
    >
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <v-autocomplete
            v-model="userData.local_timezone"
            label="Local Timezone"
            name="local_timezone"
            :items="timezones"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card flat class="mx-4 mt-8">
    <v-card-title>Default Warehouse</v-card-title>
    <v-card-subtitle>Select your default warehouse.</v-card-subtitle>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <v-autocomplete
            v-model="userData.default_warehouse_id"
            label="Default Warehouse"
            name="default_warehouse"
            :items="warehouses?.data"
            item-title="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <UserPermissionsCard
    v-if="userStore.hasPermissionTo('update_user_permissions')"
    :editMode="false"
    :enable-warnings="false"
    :permissions="userData.user_permissions"
  />

  <v-card-actions class="d-flex justify-center mx-2 mt-4">
    <v-btn size="large" variant="flat" color="primary" @click="handleCreateUser"
      >Create User</v-btn
    >
  </v-card-actions>
</template>
